import React from "react"
import theme from "theme"
import { Theme, Text, Section, Box, Image } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"services"} />
      <Helmet>
        <title>Nasze usługi | Poznaj nasze usługi w Civoxena Legal</title>
        <meta
          name={"description"}
          content={"Zapewniamy kompleksową wiedzę prawną"}
        />
        <meta
          property={"og:title"}
          content={"Nasze usługi | Poznaj nasze usługi w Civoxena Legal"}
        />
        <meta
          property={"og:description"}
          content={"Zapewniamy kompleksową wiedzę prawną"}
        />
        <meta
          property={"og:image"}
          content={"https://civoxena.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://civoxena.com/img/C_logo.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://civoxena.com/img/C_logo.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://civoxena.com/img/C_logo.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://civoxena.com/img/C_logo.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://civoxena.com/img/C_logo.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://civoxena.com/img/C_logo.png"}
        />
        <meta
          name={"msapplication-TileColor"}
          content={"https://civoxena.com/img/C_logo.png"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="80px 0" sm-padding="40px 0">
        <Override slot="SectionContent" align-items="center" />
        <Text
          as="h1"
          font="--headline1"
          md-font="--headline2"
          margin="20px 0 0 0"
        >
          Szczegółowy podział usług
        </Text>
        <Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
          W Civoxena Legal świadczymy szeroki zakres usług prawnych
          zaprojektowanych w celu zaspokojenia różnorodnych potrzeb naszych
          klientów. Niezależnie od tego, czy stoisz przed osobistym wyzwaniem
          prawnym, czy potrzebujesz wsparcia dla swojej firmy, nasz zespół jest
          tutaj, aby zaoferować fachowe porady i skuteczne rozwiązania.
        </Text>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-11"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          lg-width="100%"
          lg-align-items="center"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          flex-direction="column"
          align-items="flex-start"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
        >
          <Text
            sm-font="--headline3"
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            md-text-align="left"
          >
            Prawo korporacyjne
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            sm-margin="0px 0px 30px 0px"
            md-text-align="left"
          >
            Fuzje i przejęcia: Prowadzenie przez złożone transakcje w celu
            zapewnienia zgodności z prawem i optymalizacji wyników.
            <br /> - Planowanie strategiczne
            <br /> - Negocjowanie umów
            <br />
            <br />
            Ochrona własności intelektualnej: Ochrona innowacji i prac
            twórczych.
            <br /> - Zgłaszanie patentów
            <br /> - Rejestracja znaków towarowych
          </Text>
        </Box>
        <Box
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
        >
          <Box
            width="100%"
            height="auto"
            padding="0px 0px 70% 0px"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            overflow-x="hidden"
            overflow-y="hidden"
            position="relative"
            transform="translateY(0px)"
          >
            <Image
              position="absolute"
              width="100%"
              top="auto"
              left={0}
              src="https://civoxena.com/img/7.jpg"
              object-fit="cover"
              display="block"
              right={0}
              bottom="0px"
              min-height="100%"
            />
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-10"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            overflow-y="hidden"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
            width="100%"
            height="auto"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              src="https://civoxena.com/img/8.jpg"
              object-fit="cover"
              width="100%"
              top="auto"
              right={0}
              bottom="0px"
              min-height="100%"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
        <Box
          flex-direction="column"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
        >
          <Text
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            md-text-align="left"
          >
            Prawo rodzinne
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            Postępowanie rozwodowe: Współczujące wsparcie i strategiczna pomoc w
            trudnych chwilach.
            <br /> - Ustalenia dotyczące opieki
            <br /> - Rozliczenia finansowe
            <br />
            <br />
            Planowanie spadkowe: Zapewnienie, że Twoje dziedzictwo jest
            chronione i przekazywane zgodnie z Twoimi życzeniami.
            <br /> - Tworzenie testamentów
            <br /> - Zarządzanie powiernictwem
          </Text>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-11"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          lg-width="100%"
          lg-align-items="center"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          flex-direction="column"
          align-items="flex-start"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
        >
          <Text
            sm-font="--headline3"
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            md-text-align="left"
          >
            Obrona w sprawach karnych
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            sm-margin="0px 0px 30px 0px"
            md-text-align="left"
          >
            Reprezentacja w sprawach karnych: Agresywne strategie obrony w celu
            ochrony Twoich praw.
            <br /> - Ocena sprawy
            <br /> - Reprezentacja procesowa
            <br />
            <br />
            Przesłuchania w sprawie kaucji i ugody: Poruszanie się po niuansach
            systemu sądownictwa karnego w celu zapewnienia sobie wolności.
            <br /> - Wniosek o kaucję
            <br /> - Negocjacje z prokuratorami
          </Text>
        </Box>
        <Box
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
        >
          <Box
            width="100%"
            height="auto"
            padding="0px 0px 70% 0px"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            overflow-x="hidden"
            overflow-y="hidden"
            position="relative"
            transform="translateY(0px)"
          >
            <Image
              position="absolute"
              width="100%"
              top="auto"
              left={0}
              src="https://civoxena.com/img/9.jpg"
              object-fit="cover"
              display="block"
              right={0}
              bottom="0px"
              min-height="100%"
            />
          </Box>
        </Box>
      </Section>
      <Components.Cta />
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
